import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/quote'

class QuotationService extends ApiService {
    constructor(){
        super()
    } 
    getUrl(){
        return URL
    }
    index(page, params) {
        var queryUrl = ''
        if (params && page) {
            let xparams = params;
            if(xparams.lazyEvent){
                xparams.lazyEvent = xparams.lazyEvent.replace('"sortField":"number"', '"sortField":"created_at"')
            }
            const queryParams = Object.keys(xparams).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k])).join('&');
            queryUrl = `?page=` + page + `&` + queryParams
        }
          return http.get(`${this.getUrl()}` + queryUrl)
    }
    downloadPDF(id){
        return http.get(`${URL}/download/${id}`, {
            responseType: 'blob'
        })
    }
    getSummaryHTML(id){
        return http.get(`${URL}/download/${id}/1`)
    }
    getQuoteForClientPage(id){
        return http.get(`${URL}/${id}?pageType=client`)
    }
    addMessage(message, quoteId){
        var params = `?content=` + message.replaceAll("\n", "\\n");
        return http.post(`${URL}/${quoteId}/message`+params);
    }
    addCustomerMessage(message, quoteId){
        var params = `?content=` + message.replaceAll("\n", "\\n");
        return http.post(`${URL}/${quoteId}/customerMessage`+params);
    }
    setStatus(id, status, option, preferredVoyages) {
        return http.post(`${URL}/${id}/status`, {"status": status, "option": option, "preferred_voyages": preferredVoyages});
    }
    getSummary(id, setSurchargeVisible){
        const params = setSurchargeVisible === null ? '' : ('?surcharges_hidden=' + (setSurchargeVisible ? 'true' : 'false'));
        return http.get(`${URL}/${id}/summary/${params}`);
    }
    
    getCarrierSuggestions(toPortId, validTill, pols) {
        return http.post(`/api/carrier/suggestions/${toPortId}`, {pols: pols, date: validTill})
    }
}

export default new QuotationService()