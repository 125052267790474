<template>

      <div class="container pt-5">

        <img :src="baseURL + '/images/pdf_logo.png'" alt="" width="150"><br />
        <br />
    
    <div v-html="quoteHTML"></div>

    
    
    <template v-if="quote.status == 'pending'">
    
      <template v-if="quote.is_forward">
        <p>Select the option you would like to accept:</p>
        <div v-for="option, optionIndex in options" :key="option.id" class='form-check'>
          <input :id="'option_' + option.id" type="radio" name="option" v-bind:class="{ 'is-invalid': invalid.option }" @change="invalid.option = false" class="form-check-input" :value="option.data" v-model="quote.option" />
          <label :for="'option_' + option.id" class="form-check-label">{{option.option}}</label>
          <div class="invalid-feedback" v-if="optionIndex == options.length - 1"> Please select one of the options</div>
        </div>
      </template>

      <button type="button" class="btn btn-primary d-block mt-3" @click="unselectRadios">Unselect all voyages</button>
    
      <div class="form-check pt-3">
        <input class="form-check-input" type="checkbox" value="" id="agree"  v-bind:class="{ 'is-invalid': invalid.agree }" @change="invalid.agree = false" v-model="this.agree" true-value="1" false-value="0">
        <label class="form-check-label" for="agree">
          I agree to quotation {{quote.number}} and accept the <a href="#">Terms & Conditions</a> of NMT International Shipping B.V.
        </label>
        <div class="invalid-feedback"> Please agree to the quotation</div>
      </div>

      <br>

      <a class="btn btn-success" @click="setAccept">Accept quotation</a> <a class="btn btn-danger" @click="setDecline">Decline quotation</a>
    </template>

    <template v-if="quote.status == 'accepted'">
      <div id="approve pt-3"><br> Thank you for accepting this quotation. The NMT team will get back to you soon!</div>
    </template>

    <template v-if="quote.status == 'declined'">

      <div v-if="!feedbackSent" id="decline" class='pt-3'>Thank you for your reply on this quotation. Can you please give us feedback on our quotation so we can improve our service? Thank you very much!

          <br><br>

          <div class="mb-3">
              <label for="feedback" class="form-label">Quotation {{quote.number}} feedback</label>
              <textarea class="form-control" id="feedback" rows="5" v-model="feedback"  v-bind:class="{ 'is-invalid': invalid.feedback }" @keypress="invalid.feedback = false"></textarea>
              <div class="invalid-feedback"> Please provide some feedback</div>  
          </div>

            <a class="btn btn-secondary"  @click="sendFeedback">Send feedback</a>
      </div>
      <div v-else class='pt-3'>
        Thank you for your feedback. The NMT team will get back to you soon!
      </div>

    </template>

    </div>
    <br />

</template>

<script>
import quotationService from '@/services/QuotationService';
import { useRoute } from 'vue-router'



export default {
  data() {
    return {
        baseURL: process.env.VUE_APP_API_URL, 
        preferredVoyages: [],
        quote: {status: null},
        quoteHTML: null,
        carrier_ids: [],
        options: [],
        feedback: "",
        agree: false,
        feedbackSent: false,
        invalid: {
          option: false,
          agree: false,
          feedback: false
        }
    }
  },
  methods: {
    toLetters(num) {
        "use strict";
        var mod = num % 26,
            pow = num / 26 | 0,
            out = mod ? String.fromCharCode(64 + mod) : (--pow, 'Z');
        return pow ? this.toLetters(pow) + out : out;
    },
    unselectRadios(e){
      let radio = document.querySelector(".preferred-voyage:checked");
      if(radio) radio.checked = false;
    },
    setAccept() {
      if(this.quote.is_forward && this.options.length > 1 && !this.quote.option) {
        this.invalid.option = true;
      }
      if(!this.agree) {
        this.invalid.agree = true;
      }
      if(this.invalid.option || this.invalid.agree) {
        return false;
      }
      this.preferredVoyages = [];
      document.querySelectorAll('.preferred-voyage').forEach(voyage => {
        voyage.disabled = true;
        if(voyage.checked){
          this.preferredVoyages.push(voyage.value);
        }
      });
      this.quote.status = 'accepted'
      quotationService.setStatus(this.quote.id, this.quote.status, this.quote.option, this.preferredVoyages)
    },
    setDecline() {
      this.quote.status = 'declined'
      quotationService.setStatus(this.quote.id, this.quote.status, this.quote.option, null)
    },
    sendFeedback() {
      if(!this.feedback) {
        this.invalid.feedback = true;
        return false;
      }
      quotationService.addCustomerMessage(this.feedback, this.quote.id)
      this.feedbackSent = true;
    }
  },
  mounted() {
    let quotationId = useRoute().params.quotationId;

    quotationService.getQuoteForClientPage(quotationId).then(response => {
      this.quote = response.data
      this.quote.option = '';
    }).catch(error => {
      console.log('error', error)
    });
    quotationService.getSummaryHTML(quotationId).then(response => {
      this.quoteHTML = response.data
      this.$nextTick(() => {
        const els = document.querySelectorAll('h2[data-carrier][data-port]');
        els.forEach(el => {
          let cId = el.getAttribute('data-carrier');
          let pId = el.getAttribute('data-port');
          this.options.push({
            id: cId + '-' + pId,
            option: el.innerHTML,
            data: {carrier: cId, port: pId}
          });
        })
      })
    }).catch(error => {
      console.log('error', error)
    });
    
  }
}
</script>
